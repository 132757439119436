<template>
    <div class="location-combined-control" :class="{ prepended: !hideCurrentLocation && !currentLocationText }">
        <v-autocomplete
            v-model="internalValue"
            :messages="messages"
            :items="$vuetify.breakpoint.xsOnly ? (internalValue ? [internalValue] : []) : locationSuggestions"
            :item-text="getItemText"
            :placeholder="placeholder"
            :loading="loadingSuggestions || loadingUserLocation"
            hide-no-data
            no-filter
            return-object
            hide-selected
            clearable
            v-bind="$attrs"
            data-test-id="Location autocomplete"
            @update:search-input="handleSearchChange"
        >
            <template v-if="showAddressMessage" #message="{ key, message }">
                <div v-if="message === '__ADDRESS__'" :key="key">
                    This helps us tailor the app around climate and weather that matters to you.
                    <strong>Your address will never be publicly viewable.</strong>
                </div>
                <div v-else :key="key">
                    {{ message }}
                </div>
            </template>

            <template v-if="!hideCurrentLocation && !currentLocationText" #prepend>
                <button type="button" :aria-label="$t('locationSelection.useCurrent')" data-test-id="Current location button" @click="findCurrentLocation">
                    <v-icon :color="loadingUserLocation ? 'grey' : 'grey darken-2'">my_location</v-icon>
                </button>
            </template>

            <template v-if="!hideCurrentLocation && currentLocationText && !internalValue" #append>
                <button type="button" class="text-body-1 text-sm-body-2 primary--text" data-test-id="Current location button" @click.capture.stop="findCurrentLocation">
                    {{ $t('locationSelection.useCurrent') }}
                </button>
            </template>
        </v-autocomplete>

        <v-card v-if="$vuetify.breakpoint.xsOnly && mobileSuggestionsShowing && locationSuggestions.length !== 0">
            <v-list data-test-id="Location autocomplete suggestions">
                <v-list-item-group @change="handleSuggestionSelection">
                    <v-list-item v-for="location in locationSuggestions" :key="location.id" :value="location">{{ getItemText(location) }}</v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
    </div>
</template>
<script lang="ts">
import Vue from '@/vueTyped';
import { MBAddressObj, LngLat } from '@/types';
import debounce from 'lodash/debounce';
import { geocode } from '@/util.mapbox';
import { getActiveLocation } from '@/util.location';

export default Vue.extend({
    name: 'LocationAutocomplete',
    props: {
        placeholder: {
            required: false,
            type: String,
            default: ' '
        },
        value: {
            required: false,
            type: Object,
            default: null,
        },
        geocodingParamTypes: {
            type: Array,
            required: false,
            default: (): null => null,
        },
        currentLocationText: {
            type: Boolean,
            default: false,
        },
        hideCurrentLocation: {
            type: Boolean,
            default: false,
        },
        showAddressMessage: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            previousSearch: (this as any).getItemText(this.value),
            locationSuggestions: this.value ? [this.value] : [] as any[],
            loadingSuggestions: false,
            mobileSuggestionsShowing: false,
            loadingUserLocation: false,
        };
    },
    computed: {
        currentCoordinates(): LngLat | null {
            if (this.$store.state.activeLocation) {
                const [lng, lat] = this.$store.state.activeLocation.center;
                return [lng, lat];
            } else if (this.$store.state.account.currentUser) {
                const { lng, lat } = this.$store.state.account.currentUser;
                return [lng, lat];
            } else {
                return null;
            }
        },
        messages() {
            const messages = [];
            if (Array.isArray(this.$attrs.messages)) {
                messages.push(...this.$attrs.messages);
            } else if (this.$attrs.messages) {
                messages.push(this.$attrs.messages);
            }
            if (this.showAddressMessage) {
                messages.unshift('__ADDRESS__');
            }
            return messages;
        },
        internalValue: {
            get(): null | MBAddressObj {
                return this.value;
            },
            set(val: null | MBAddressObj) {
                this.$emit('input', val);
            },
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (value) {
                    if (!this.locationSuggestions.includes(value)) {
                        this.locationSuggestions = [value];
                    }
                } else {
                    this.locationSuggestions = [];
                }
            },
        },
    },
    methods: {
        getItemText(item: any) {
            return item?.place_name ?? item?.text ?? '';
        },
        fetchLocations: debounce(async function fetchLocations(this: any, search: string) {
            this.geocodingError = false;
            this.loadingSuggestions = true;
            try {
                const res = await geocode(search, {
                    proximity: this.currentCoordinates,
                    limit: this.$vuetify.breakpoint.xsOnly ? 3 : 4,
                    types: this.geocodingParamTypes
                });
                this.locationSuggestions = res.features;
                this.mobileSuggestionsShowing = true;
                if (this.$vuetify.breakpoint.xsOnly) {
                    await this.$nextTick();
                    this.$el.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }
            } catch (err) {
                this.geocodingError = true;
            } finally {
                this.loadingSuggestions = false;
            }
        }, 500),
        async findCurrentLocation() {
            this.loadingUserLocation = true;
            const result = await getActiveLocation();
            if (result.location) {
                this.internalValue = result.location;
            } else {
                if (result.error) {
                    this.$store.dispatch('alertUser', {
                        type: 'error',
                        message: result.error,
                    });
                }
            }
            this.loadingUserLocation = false;
        },
        handleSearchChange(search: null | string) {
            const searchChanged = search !== this.previousSearch;
            const searchIsntCurrentValue = search !== this.getItemText(this.value);
            if (search && typeof search === 'string' && searchChanged && searchIsntCurrentValue) {
                this.previousSearch = search;
                this.fetchLocations(search);
            }
        },
        handleSuggestionSelection(location: MBAddressObj) {
            if (this.value !== location) {
                this.internalValue = location;
            }

            this.mobileSuggestionsShowing = false;
        }
    },
});
</script>
<style lang="postcss" scoped>
.location-combined-control.prepended :deep(.v-input__prepend-outer) {
    z-index: 1;
    margin-left: var(--spacing-2);
}
.location-combined-control.prepended :deep(.v-input__slot) {
    padding-left: var(--spacing-11) !important;
    margin-left: calc( -1 * var(--spacing-11));
    width: calc(100% + var(--spacing-11));
}

</style>
